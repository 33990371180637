export const Role = {
  EmployerSetup: "EmployerSetup",
  Views: "Views",
  AuthorizationHold: 'AuthorizationHold',
  Underwrting: "Underwriting",
  Group: "Group",
  Client: "Client",
  Tellering: "Tellering",
  DepositAccount: "DepositAccount",
  Reports : "Reports",
  Cards: "Cards",
  CreditOperations: "CreditOperations",
  Documents: "Documents",
  Comments: "Comments",
  User: "User",
  ApiConsumersAndKeys: "ApiConsumersAndKeys",
  Roles: "Role",
  Communications: "Communications",
  Funds: "Funds",
  AccountingRates: "AccountingRates",
  LoanAccounts: "LoanAccounts",
  Tasks: "Tasks",
  Product: "Product",
  Centre: "Centre",
  CustomField: "CustomField",
  Branch: "Branch",
  Access: "Access",
  TransactionChannels: "TransactionChannels",
  AgentDashboard: "AgentDashboard",
  AccountOfficerReassignment: "AccountOfficerReassignment",
  TeamManagement: "TeamManagement",
  Activity: "Activity",
  GeneralSetup: "GeneralSetup",
  Templates: "Templates",
}